@import '../../scss/colors';

.str-chat-channel-list {
  .str-chat__channel-list-messenger {
    .str-chat__channel-list-messenger__main {
      padding: 8px !important;
      .channel-preview__container {
        margin-left: 0px;
      }
    }
  }
}

.str-chat-channel {
  .str-chat__container {
    .str-chat__input-flat {
      z-index: 0;
    }
  }
}

.unread-messages {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  color: white;
  background-color: $greenIcon;
}

.last-message-time {
  color: $textTimeLastMessage;
}

.preview-message {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

// 'sm': '640px',
@media (min-width: 640px) {
  .str-chat {
    height: 75vh;
  }
}

// 'md': '768px',
@media (min-width: 768px) {
  .str-chat {
    height: 84vh;
  }
}

// 'lg': '1024px',
@media (min-width: 1024px) {
  .str-chat {
    height: 78vh;
  }
}

// 'xl': '1280px',
@media (min-width: 1280px) {
  .str-chat {
    height: 86vh;
  }
}

// '2xl': '1536px',
@media (min-width: 1536px) {
}

.compact-view {
  border-radius: 15px;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0px !important;
}

span.profile-link {
  display: block;
  color: $greenIcon;
}
