@media (min-width: 1400px) {
  .middle-sidebar-left {
    max-width: 1060px;
  }
}

@media (min-width: 1200px) {
  .middle-sidebar-left {
    max-width: 1060px;
  }
}

@media (max-width: 991.98px) {
  .profile {
    margin-top: 65px;
    .row {
      // padding-top: 100px;
      .main-body {
        display: block;
        .uploader-container {
          display: block;
          h4 span {
            text-align: center !important;
          }
          .avatar {
            display: table;
            margin-right: auto !important;
            margin-left: auto !important;
          }
        }
      }
    }
  }
}
