@import "../../scss/colors";

.card {
  &.friends-container {
    .card-body {
      h4 span {
        color: $textColorGreenLight;
      }
      .btn-green {
        background-color: $trendingPostGreenBg;
      }
      .btn-reject {
        color: $iconColor !important;
      }
    }
  }
}

.loading-indicator {
  height: 20px;
  width: 20px;
}

.request-avatar { 
  object-fit: cover;
  border-radius: 7px;
  height: 45px;

}
