@import '../colors';
$nav-logo-height: 90px;
$nav-logo-height-small: 71px;

.nav-header {
  .mob-menu-sm {
    display: none;
    @media (max-width: 575px) {
      display: inline-block !important;
    }
  }

  .mob-menu {
    display: none;
    @media (max-width: 575px) {
      display: none !important;
    }
  }
}

.navigation {
  margin-top: calc(#{$nav-logo-height} + 6px);
  padding-top: 0px;
  .nav-content {
    ul {
      li:not(.logo) {
        a {
          padding-left: 8px;
          span {
            color: $switchInactiveColor;
          }
        }
      }
    }
  }
}

.right-chat {
  margin-top: calc(#{$nav-logo-height} + 6px) !important;
  padding-top: 0px;
}

.modal-popup-chat {
  right: 295px;
  &.closed {
    right: 20px;
  }
}

@media (max-width: 991.98px) {
  .right-chat {
    margin-top: calc(#{$nav-logo-height-small + 6px}) !important;
    padding-top: 0px !important;
    display: none;
  }

  .nav-active {
    margin-top: calc(#{$nav-logo-height-small}) !important;
  }
}
