$white: #ffffff;
$apple-color: #0d1119;
$fb-color: #3f62ae;
// $black:      #222;

// BASIC COLORS
$primaryColor: #09a68f;
$primaryLight: #0fdbbd;
$secondaryLight: #4af5dc;

// SCREEN COLORS
$datingColor: #927fec;

$guidesColor: #be4597;
$guidesSecondaryColor: #db76c4;
$groupsColor: #38c1ad;
$recoveryColor: #81d9e0;
$soberHelpColor: #69d8ed;
$soberHelpColorLight: #98efff;
$travelColor: #e6d757;
$friendColor: #09a68f;
$hotlineColor: #69d8ed;
$hotlineIconColor: #eff4f8;
$searchBarColor: #b1b1b1;
$newPageBackground: #f7f7f7;
$pageBackground: #f5f5f5;
$borderColor: #dbdbdb;
$recoveryIconColor: #64c8f3;
$trendingPostTitleColor: #0aa68f;
$trendingPostGreenBg: #0aa68f;
$trendingPostBlueBg: #43b4d8;
$friendBtnColor: rgb(199, 231, 226);

//BackgroundColors
$inputBackground: #fdfdfd;
$datingBannerBackground: rgba(187, 172, 255, 0.4);
$hotlineBannerBackground: rgba(105, 216, 237, 0.4);
$opaqueWhiteBackground: rgba(255, 255, 255, 0.1);
$notificationBadgeBackground: #eb5292;
$backgroundColorZicorn: #e4ebeb;
$hotlineCardBackgroundBlue: #2aaee7;
$subTabsBackGround: #ededed;
$audioBackground: #1b7083;
$audioHomeBackground: #d4e3e8;

// Text Colors
$textColor: #8b8b8b;
$textColorWhite: #ffffff;
$textColorDark: #0d0d0d;
$textColorLight: #fcfcfc;
$textColorBlack: #000000;
$textColorGrey: #8b8b8b;
$textColorDarkGrey: #3c3c3c;
$textColorDarkerGrey: #5b5b5b;
$textColorShuttleGrey: #5e6164;
$textColorGrey70: #b3b3b3;
$darkCyanBlue: #262a2f;
$textBannerDark: #2e2e2e;
$textColorGreen: #006200;
$textColorGreenLight: #68807d;
$textColorGreenDark: #3b5956;
$iconColor: #8d9998;

// Onboarding Colors
$onboardingDark: #2b2b2b;
$onboardingLight: #454545;

// Alpha Coloring
$alpha10Percent: 1a;
$alpha40Percent: 66;

// Button Colors
$genericButtonBackground: #e9e9e9;
$disabledButtonBackground: #eaeaea;
$unselectedButtoncolor: #9f9f9f;

// Modal Colors
$modalPatchColor: #e8e8e8;
$ModalBackground: #f8f8f8;

// Card Colors
$cardBackground: #ffffff;

// red - cancel, etc.
$red: #e18784;

// blue action button color
$blue: #0000ff;
$fbBlue: #3b5998;

// guide image container color
$pinkColor: #db76c4;
$pendingFriendRequestColor: #0da78f;
$newTextColor: #575757;
$newHeadingColor: #2e2e2e;
$newHeadingColorWhite: #ffffff;
$newTextColorDescription: #818181;
$datingOcupation: #6c6c6c;

//CheckBox
$checkBoxOutline: #d9dfe5;

//BorderColors
$seperatorColor: #efefef;
$textSeparatorColor: #dcdcdc;

//BannerColors
// : Dating
// export const DatingBannerColors = [
//     '#E79AC8',
//     '#B477D9',
//     '#927FEC',
//     '#6E59D0',
// ]
// export const GroupsBannerColors = [
//     '#33FCCC',
//     '#43D1BC',
//     '#31C2AD',
//     '#13BAA1',
//     '#069C86',
// ]
// export const SoberHelpBannerColors = [
//     '#98EFFF',
//     '#7FC2F3',
//     '#69D8ED',
//     '#50C5DB',
//     '#3CB3CA'
// ]

$onlineIndicator: #1cd253;
$headerIconColor: #1e3e3a;

// DARK THEME COLORS
$darkBg: #222325;
$navBg: #3d3f41;
$headerBtnText: #aeaeae;
$navigationHomeBg: #20302e;
$navigationSoberHelpBg: #253136;
$navigationAudioBg: #212b2d;
$navigationDatingBg: #2d2c38;
$navigationSoberTipsBg: #343028;
$navigationText: #68807d;
$darkTextColorGreenLight: #aeaeae;
$darkTextColorGreenDark: #d0d0d0;
$darkIconColor: #aeaeae;
$datingColor: #7972cf;

// Sober Tracker
$greenIcon: #09a68f;
$placeHolderColor: #68807d;
$loginButton: #09a68f;
$blackText: #2e2e2e;
$whiteScreen: #f5f5f5;
$greenScreen: #31c6b0;
$bigIcon: #1e3e3a;
$subtitle: #f5f5f5;
$blueIcon: #42b4d8;
$activeHeaderBg: #ededed;
$dashStatsCount: #05221f;
$secondaryTitle: #1e3e3a;
$textTags: #1e3e3a;
$bgTags: #d9f0f7;
$sadFaceBg: #db4545;
$textPersonOnDash: #011412;
$switchInactiveColor: #8d9998;
$grayButtonBg: #ececec;
$subPointsModal: #3b5956;
$pink: #eb5292;
$grayBg: #f7f7f7;
$cardNoteBg: #99e0ef;
$cardNoteCornerBg: #7ab3bf;
$cardNoteAddBg: #f3f3f3;
$cardNoteAddCornerBg: #c2c2c2;
$cardNoteAddIcon: #adadad;
$notDownloadedAppText: #3b5956;
$defaultAvatarPatientsBg: #f1f1f1;
$notPairedYetBtnBg: #d96da7;
$selectArrowIcon: #aeaeae;
$calendarBg: #09a68f;
$uploadBtnBg: #31c6b0;
$previewImageBg: #f2f2f2;
$textTimeLastMessage: #8d9998;
$infoColor: #bb7751;
$headerMenuBgSelected: #e5f0f4;
$successAlertBg: #13ca45;
$bubbleChatBg: #f2f2f7;
$newMessageColor: #43b4d8;
