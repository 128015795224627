@import '../../scss/colors';
@import '../../scss/common';

.notification {
  h1 {
    color: $white;
  }

  .notification-btn {
    background-color: $white;
    width: 75px;
  }

  .buttons-bottom {
    .Toastify__spinner {
      width: 35px;
      height: 35px;
      border-color: #0fa137;
      border-right-color: $white;
    }
    .time-countdown {
      position: absolute;
      right: 27px;
      color: $white;
    }
  }
}
