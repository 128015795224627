@import '../../scss/colors';
@import '../../scss/common';

.note-card {
  height: 300px;
  background-color: $cardNoteBg;

  &.add-new {
    background-color: $cardNoteAddBg;
    .note-card-body {
      background-color: $cardNoteAddBg;
      i {
        font-size: 50px;
        color: $cardNoteAddIcon;
      }
      .corner-box {
        background-color: $cardNoteAddCornerBg;
      }
    }
  }

  .note-card-body {
    width: 300px;
    height: 300px;
    position: relative;
    border-radius: 10px;
    display: flex;
    padding-top: 10px;
    padding-bottom: 35px;

    .corner-box {
      width: 30px;
      height: 30px;
      position: absolute;
      background-color: $cardNoteCornerBg;
      bottom: 0;
      left: 0;
      clip-path: polygon(0 0, 100% 0, 100% 100%);
    }

    .corner-empty {
      width: 30px;
      height: 30px;
      position: absolute;
      background-color: $white;
      bottom: 0;
      left: 0;
      clip-path: polygon(0% 0, 100% 100%, 0 100%);
    }

    .note-btn-area {
      display: flex;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    ul.my-notes {
      height: 240px;
      overflow: hidden;
      overflow-y: scroll;
      li.my-note {
        list-style-type: inside;
        list-style: inside;
        margin-bottom: 5px;
      }
    }

    .editor-body {
      width: 100%;
      height: 240px;
      overflow: hidden;
      overflow-y: scroll;
    }
  }
}

.not-downloaded {
  color: $notDownloadedAppText;
  span {
    color: $textPersonOnDash;
  }
}

.close-button-container {
  position: absolute;
  top: 25px;
}

.upload-container {
  .upload-btn {
    background-color: $uploadBtnBg;
  }

  p.instructions {
    color: $notDownloadedAppText;
  }

  .preview {
    width: 250px;
    height: 150px;
    background-color: $previewImageBg;
    i {
      font-size: 60px;
      font-weight: 100;
      color: #b5b5b5;
    }
  }
}

.buttons-time {
  position: absolute;
  right: 18px;
  top: 18px;
  .btn-time {
    padding-bottom: 3px;
    color: #8c8c8c;

    &.active {
      color: #000;
      border-bottom: 3px solid #000;
    }
  }
}

.report-calendar {
  .circle-container {
    width: fit-content;
    height: fit-content;

    .empty-circle {
      background-color: $primaryColor;
      opacity: 0.1;
    }
  }
  .green-circle {
    background-color: $primaryColor;
    width: 55px;
    height: 55px;
    line-height: 55px;

    i {
      line-height: 55px;
    }
  }
  .red-circle {
    background-color: #d96da7;
    width: 55px;
    height: 55px;
    line-height: 55px;

    i {
      line-height: 55px;
    }
  }
  .custom-calendar > div {
    display: none;
  }
  .weekday {
    color: #aeaeae;
  }
  .arrow {
    top: 3px;
    position: relative;

    .disabled {
      color: #aeaeae;
    }
  }
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.select-date-style {
  background-color: #000;
  border-radius: 16px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 2;
}

.select-week-date-style {
  z-index: 1;
}

.report-item {
  h6 {
    &.title {
      color: $textPersonOnDash;
    }
    &.value {
      color: $secondaryTitle;
    }
    &.feeling-label {
      color: $placeHolderColor;
    }
  }

  .feeling-division {
    border-bottom: #000000 1px solid;
  }
  .feeling-division-sides {
    height: 5px;
    border-right: #000000 1px solid;
    border-left: #000000 1px solid;
  }
}
