@import '../../scss/colors';

@media (max-width: 991.98px) {
  .modal-popup-chat {
    bottom: 56px;
    right: 10px !important;
    .modal-popup-wrap {
      .mini-chat {
        max-height: 42vh;
      }
    }
  }
}

.str-chat__message--me .str-chat__message-text-inner,
.str-chat__message-simple--me .str-chat__message-text-inner {
  background: $textColorGreenDark !important;
  color: white;
  border-radius: var(--border-radius) calc(var(--border-radius-sm) / 2)
    var(--border-radius) var(--border-radius) !important;
}

.str-chat__message-text-inner,
.str-chat__message-simple-text-inner {
  background: $bubbleChatBg !important;
  border-radius: calc(var(--border-radius-sm) / 2) var(--border-radius)
    var(--border-radius) var(--border-radius) !important;
}

/* Right triangle placed top left flush. */
// .tri-right.border.left-top:before {
//   content: ' ';
//   position: absolute;
//   width: 0;
//   height: 0;
//   left: -40px;
//   right: auto;
//   top: -8px;
//   bottom: auto;
//   border: 32px solid;
//   border-color: #666 transparent transparent transparent;
// }
.tri-right.left-top:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: -11px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 11px solid;
  border-color: $bubbleChatBg transparent transparent transparent;
}

.tri-right.right-top:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  right: -11px;
  left: auto;
  top: 0px;
  bottom: auto;
  border: 11px solid;
  border-color: $textColorGreenDark transparent transparent transparent;
}

.talk-bubble {
  margin: 0px;
  display: inline-block;
  position: relative;
  height: auto;
  width: 80%;
  &.left-top {
    margin-right: 50px;
    margin-left: 50px !important;
    background-color: $bubbleChatBg;
    p {
      &.user {
        font-weight: 600;
      }
      &.time-message {
        color: #666668;
      }
    }
  }

  &.right-top {
    margin-left: 50px;
    margin-right: 50px;
    background: $textColorGreenDark;
    p {
      color: $white;
      &.user {
        font-weight: 600;
      }
      &.time-message {
        color: $white;
      }
    }
  }
}

.str-chat__message p,
.str-chat__message-simple p {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  &.user {
    font-weight: 600;
  }
  &.time-message {
    font-size: 12px;
    text-align: right;
  }
}

.chat-avatar {
  position: absolute;
  top: 0;
}

.str-chat__input-flat .str-chat__input--emojipicker {
  left: -28px;
}

.new-msg-btn {
  background: $newMessageColor !important;
  height: 45px !important;
}
