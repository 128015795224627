@import './colors';

$box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

.p-25 {
  padding: 0.75rem !important;
}

.px-25 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.pt-25 {
  padding-top: 0.75rem !important;
}

.w185 {
  width: 185px !important;
}

.w80 {
  width: 80px !important;
}

.h140 {
  height: 140px !important;
}

.max-width-100 {
  max-width: 100% !important;
}

.custom-gray-bg {
  background-color: $grayBg;
}

.vh-50 {
  height: 50vh !important;
}

.hide-overflow-x {
  overflow-x: hidden;
}
