@import '../../scss/colors';
@import '../../scss/common';

.icon {
  cursor: pointer;
  margin-right: 50px;
  line-height: 60px;
}

.icon span {
  background: #f00;
  padding: 7px;
  border-radius: 50%;
  color: #fff;
  vertical-align: top;
  margin-left: -25px;
}

.icon img {
  display: inline-block;
  width: 26px;
  margin-top: 4px;
}

.icon:hover {
  opacity: 0.7;
}

.logo {
  flex: 1;
  margin-left: 50px;
  color: #eee;
  font-size: 20px;
  font-family: monospace;
}

.notifications {
  width: 375px;
  height: 0px;
  opacity: 0;
  position: absolute;
  top: 80px;
  right: 32px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.notifications-bg-dark {
  background-color: #3d3f41 !important;
}

.notifications-item {
  display: flex;
  border-bottom: 1px solid #adb5bd;
  padding: 12px 9px;
  margin-bottom: 0px;
  cursor: pointer;
}

.notifications-item img {
  display: block;
  width: 50px;
  height: 50px;
  margin-right: 9px;
  border-radius: 50%;
  margin-top: 2px;
}

::-webkit-scrollbar {
  display: none;
}

a.selected {
  span {
    color: $secondaryTitle !important;
  }
  background: $headerMenuBgSelected !important;
  i {
    background: $trendingPostBlueBg !important;
    color: #fff !important;
  }
}

.loosid-header-btn {
  color: #8d9998;
  &.selected {
    color: #1e3e3a;
  }
}

.nav-header {
  .nav-top {
    a.menu-icon {
      width: auto !important;
    }
  }
}

.dropdown-menu {
  h4 {
    color: $secondaryTitle;
  }
}
