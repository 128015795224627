.str-chat-channel.mini-chat {
  max-height: 30vh;
  .str-chat__main-panel {
    padding: 0px !important;
    .str-chat__list {
      padding: 0px 30px 0 5px;
      .str-chat__reverse-infinite-scroll {
        padding-top: 0px;
        ul.str-chat__ul li {
          .str-chat__date-separator {
            padding: 5px 0 0 0;
          }
          
        }
      }
    }
    .str-chat__input-flat {
      padding: 0 10px;

    }
  }
}

.channel-preview__container {
  height: 56px;
  margin-bottom: 8px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px;
}

.channel-preview__container:hover {
  // background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07);
  // transition: background 0.1s ease-in-out;
}

.channel-preview__container.selected {
  // background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07);
  // transition: background 0.1s ease-in-out;
}

.channel-preview__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  width: 100%;
}

.channel-preview__content-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  height: 18px;
  margin-bottom: 4px;
}

.channel-preview__content-name {
  font-family: Montserrat, sans-serif;
  margin: 0;
  max-width: 158px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.channel-preview__content-message {
  font-family: Montserrat, sans-serif;
  line-height: 16px;
  margin: 0;
  height: 16px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}