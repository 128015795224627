.h60 {
  height: 60px !important;
}

.member-avatar {
  border-radius: 0.8rem;
}

.username {
  white-space: nowrap; 
  text-overflow: ellipsis;
  height: 20px;
  width: 70px;
  overflow: hidden;
}