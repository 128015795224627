@import './colors';
@import './common';
@import './components/loosid_components';
@import './abstract//loosid_dark';

:root {
  --primary-color: #09a68f;
}

.nav-header {
  .nav-caption {
    border-bottom-color: $borderColor;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding-bottom: 2rem;
  }
  .avatarUrl {
    border-radius: 0.5rem;
  }
  .nav-top {
    .brand-logo {
      img {
        margin-left: 35px;
        &.small {
          margin-left: 20px;
        }
      }
    }
  }
  .menu-icon {
    i {
      color: $headerIconColor !important;
    }
  }
  .home-header-component {
    .loosid-header-btn {
      &.d-new-selected,
      &.d-likes-selected,
      &.d-matches-selected {
        background-color: $datingColor !important;
        color: $textColorWhite !important;
      }
      &.p-posts-selected,
      &.p-photos-selected,
      &.p-goals-selected {
        background-color: $white !important;
      }
    }
  }
  .menu-icon .dot-count {
    top: 9px;
    right: 10px;
    width: 8px;
    height: 8px;
  }
}

.drawer-tiles {
  padding-right: 1.5rem;
  div {
    width: 40px;
    height: 40px;
    border-radius: 10px;

    p {
      font-size: 9px;
      text-align: center;
      white-space: pre;
      line-height: unset;
    }

    i {
      color: $white;
      font-size: 20px;
    }
  }

  .color-groups {
    background: $groupsColor;
  }
  .color-notif {
    background: $notificationBadgeBackground;
  }
}

// 'sm': '640px',
@media (min-width: 640px) {
  .nav-header {
    .nav-top {
      .brand-logo {
        img {
          margin-left: 20px;
          &.small {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

// 'md': '768px',
@media (min-width: 768px) {
  .nav-header {
    .nav-top {
      .brand-logo {
        img {
          margin-left: 20px;
          &.small {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

.right-chat {
  z-index: 5;
}

.loosid-black-text {
  color: $blackText;
}

button.sober-track-btn {
  // width: 349px;
  height: 58px;
  border-radius: 14px;
  padding: 12px, 25px, 12px, 25px;
  font-size: 16px;
  box-shadow: $box-shadow;
  margin-bottom: 1rem;
  background-color: $loginButton;

  img {
    width: 21px;
    height: 27px;
    margin-right: 0.5em;
  }
  &:focus {
    background-color: $loginButton;
  }
}

.sober-track-form {
  input.form-control {
    border: none;
    border-radius: 20px;
  }
  i.icon-field {
    color: $greenIcon;
  }
  i.right-position {
    left: unset;
    right: 20px;
  }
  textarea.style2-textarea {
    height: 120px;
    border-radius: 20px;
    &:focus {
      border-color: transparent !important;
    }
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $placeHolderColor;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $placeHolderColor;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $placeHolderColor;
  }
}

.nav-header {
  .sober-track-header {
    .menu-icon {
      i {
        background-color: white;
        color: $bigIcon;
      }
      &.active {
        i {
          background-color: $activeHeaderBg;
          color: $greenIcon !important;
        }
      }
    }
  }
  background: $whiteScreen;
}

.secondaryTitle {
  color: $secondaryTitle;
}

.tags {
  color: $textTags;
  background-color: $bgTags;
  border-radius: 0.8rem;
}

.sub-point {
  color: $subPointsModal;
}

.sad-face {
  background-color: $sadFaceBg;
}

.modal-switch-component {
  .st-switch-btn {
    h4,
    i {
      color: $switchInactiveColor;
    }
    &.activated {
      background-color: $white;

      h4 {
        color: $textPersonOnDash;
      }
      i {
        color: $loginButton;
      }
    }
    &.green {
      background-color: $loginButton;
      h4,
      i {
        color: $white;
      }
    }
    &.gray {
      background-color: $grayButtonBg;
      h4,
      i {
        color: $blackText;
      }
    }
    &.pink {
      background-color: $pink;
      h4 {
        color: $white;
      }
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .assignments-done-title {
    border-bottom: 4px solid $greenIcon;
  }
  .section-report {
    border-bottom: 4px solid $textPersonOnDash;
    position: absolute;
    left: 10px;
  }
}

.dashboard-data,
.patients-data {
  .title-table {
    color: $placeHolderColor;
  }
  .days-sober {
    color: $dashStatsCount;
  }
  .name-user {
    color: $textPersonOnDash;
    span {
      color: $placeHolderColor;
    }
  }
}

.form-group.icon-input i.right {
  top: 12px !important;
  right: 14px !important;
  left: unset;
  color: $selectArrowIcon;
}

.form-group {
  .react-date-picker {
    padding: 0px;
    padding-left: 24px;
    border: none;
    border-radius: 20px;
    .react-date-picker__wrapper {
      height: 55px;
      border: none;
      input {
        padding-left: 0px;
      }
    }
    &.not-show-separators {
      span.react-date-picker__inputGroup__divider {
        display: none;
      }
    }
  }
  .rc-time-picker {
    padding: 0px;
    border-radius: 20px;
    input {
      height: 60px;
      border: none;
      border-radius: 20px;
      color: #000;
      font-weight: 600;
      font-size: 14px;
      padding-left: 24px;
    }
  }
}

select.custom-select {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  height: 47px;
  color: $secondaryTitle;
  padding-right: 35px;
}

.Toastify__toast--success {
  background-color: $successAlertBg !important;
}

.react-confirm-alert-overlay {
  z-index: 1001;
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  display: flex;
  justify-content: center;
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
  position: absolute;
  top: 0;
  z-index: 2;
  background-color: $cardNoteBg;
  margin-top: 0.25rem;
  width: 93%;
  left: 16px;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.DraftEditor-root {
  padding-top: 1rem;
}

.DraftEditor-editorContainer {
  margin-top: 0.5rem;
  height: 205;
}

.public-DraftEditorPlaceholder-inner {
  margin-top: 1rem;
}

.link-invite-by-file {
  text-decoration: underline;
}

.react-confirm-alert-button-group {
  button {
    &.bg-red {
      background-color: $red;
      color: $white;
    }

    &.bg-light {
      background-color: $primaryColor !important;
    }
  }
}
