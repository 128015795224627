@import "../colors";
$feedColor: #0aa68f;
$soberHelpColor: #43b4d8;
$audioColor: #1c7083;
$datingColor: #7972cf;
$guideColor: #d96da7;
$soberTipsColor: #daa14c;
:root {
  --f7-theme-color: $darkBg;
}

.theme-dark {
  background-color: $darkBg;
  .middle-sidebar-header,
  .middle-sidebar-right-content,
  .nav-header {
    background-color: $navBg !important;
    box-shadow: none !important;
  }
  .main-content {
    background-color: $darkBg;
  }
  .card {
    background-color: $navBg !important;
    &.user-post {
      .card-body {
        h4 span {
          color: $darkTextColorGreenLight;
        }
        p {
          color: $darkTextColorGreenDark;
        }
        .post-count,
        .like-count {
          color: $darkIconColor;
          i {
            color: $darkIconColor;
          }
        }
      }
    }
    &.guide-item {
      .card-body {
        h4 {
          color: $darkTextColorGreenLight !important;
        }
      }
      &.selected {
        background-color: $guidesColor !important;
        h4 {
          color: $white !important;
        }

      }
    }
    &.card-guide-container,
    &.tp-container {
      background-color: $darkBg !important;
      p {
        color: $textColorWhite !important;
      }
    }
    &.friends-container {
      .card-body {
        h4 span {
          color: $darkTextColorGreenLight;
        }
      }
    }
    .members-container {
      .username {
        color: $textColorWhite !important;
      }
    }
    &.boozeless-search-container {
        h4 > span.title {
          color: $white;
        }
        h4 > span.secondary {
          color: $disabledButtonBackground;
        }
        figure.icon {
          background-color: $textColorGreenDark;
          i {
            color: $guidesColor
          }
        }
    }
    .dating-container {
      background: black !important;
      .card-image {
        background: black !important;
      }
    }
    .card-body.dating-data {
      h4 > span.secondary {
        color: $disabledButtonBackground !important;
      }
    }
  }

  .home-header-component {
    background-color: $darkBg !important;
    .loosid-header-btn {
      color: $headerBtnText !important;
      &.feed-selected {
        background-color: $friendColor !important;
        color: $textColorWhite !important;
      }
      &.hl-selected {
        background-color: $hotlineColor !important;
        color: $textColorWhite !important;
      }
      &.sober-help-selected, &.treatment-selected, &.telehelp-selected {
        background-color: $hotlineColor !important;
        color: $textColorWhite !important;
      }
    }
  }

  .navigation {
    background-color: $darkBg !important;
    a {
      span {
        color: $navigationText !important;
      }
      &.sober-tips {
          &:hover span {
            color: $soberTipsColor !important;
          }
      }
      &.guide {
        &:hover span {
          color: $guideColor !important;
        }
      }
      &.dating {
        &:hover span {
          color: $datingColor !important;
        }
      }
      &.audio {
        &:hover span {
          color: $audioColor !important;
        }
      }
      &.audio {
        &:hover span {
          color: $audioColor !important;
        }
      }
      &.sober-help {
        &:hover span {
          color: $soberHelpColor!important;
        }
      }
      &.home {
        &:hover span {
          color: $feedColor !important;
        }
      }
    }
    a.selected {
      span {
        color: $textColorWhite !important;
      }
      &.home {
        background-color: $navigationHomeBg !important;
      }
      &.sober-help {
        background-color: $navigationSoberHelpBg !important;
      }
      &.audio {
        background-color: $navigationAudioBg !important;
      }
      &.dating {
        background-color: $navigationAudioBg !important;
      }
      &.guide {
        background-color: $navigationAudioBg !important;
      }
      &.sober-tips {
        background-color: $navigationSoberTipsBg !important;
      }
    }
  }
  .nav-header {
    .menu-icon {
      i {
        color: $textColorWhite !important;
      }
    }
  }
  .input-file + .js-labelFile.btn-tertiary,
    .modal-popup-chat .modal-popup-wrap,
    .theme-dark-bg {background-color: $navBg !important;}

  .dating-carousel {
    .slick-arrow {
      background: #7d7d7d !important; 
      &:before {
        color: white !important;
      }
    }
  }
  .login {
    .form-group {
        input.style2-input {
            color: #adb5bd !important;
        }
    }
  }
}
